import { gql } from '@apollo/client'

export default gql`
query getAllProducts(
  $storeId: Int
  $page: Int!
  $limit: Int!
  $isUnassigned: Boolean
  $search: String
  $status: String
  $categoryIds: [Int]
  $subCategoryIds: [Int]
  $childSubCategoryIds: [Int]
  $order: String
  $minPrice: Int
  $maxPrice: Int
  $categoryName: String
  $subCategoryName: String
  $childSubCategoryName: String
) {
  getAllProducts(
    store_id: $storeId
    page: $page
    limit: $limit
    isUnassigned: $isUnassigned
    search: $search
    status: $status
    categoryIds: $categoryIds
    subCategoryIds: $subCategoryIds
    childSubCategoryIds: $childSubCategoryIds
    order: $order
    minPrice: $minPrice
    maxPrice: $maxPrice
    categoryName: $categoryName
    subCategoryName: $subCategoryName
    childSubCategoryName: $childSubCategoryName
  ) {
    data {
      id
      title
      slug
      like_count
      comment_count
      sharepost_count
      dis_price
      dis_listPrice
      total_variants
      total_inventory_quantity
      productLikes {
        id
      }
      images {
        media_id
        src
        id
      }
      store {
        id
        name
      }
      attributes {
        id
        name
        value
      }
      categories {
        id
        category {
          id
          name
        }
        subCategory {
          id
          name
        }
        childSubCategory {
          id
          name
        }
      }
      likes {
        id
      }
      bookmark {
        id
        collection {
          id
          name
          user_id
          isPrivate
        }
      }
      like_count
      comment_count
      sharepost_count
      total_variants
      review_count
      feedback_count
      bookmark_count
    }
    total
    minPrice
    maxPrice
  }
}
`
