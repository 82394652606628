import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './CollectionByProduct.module.scss'
import GET_ALL_BOOKMARK from 'src/graphql/queries/getAllBookmark'
import AllProduct from 'src/components/ProductPage/AllProduct'
import Lottie from 'react-lottie-player'
import emptyCart from '../../../components/onbording/lottie/test-file.json'
import classNames from 'classnames'
import GET_SINGLE_COLLECTION_LIKE from 'src/graphql/queries/getCollectionSingleLike'
import ADD_COLLECTION_LIKE from 'src/graphql/mutations/addCollectionLike'
import CHANGE_COLLECTION_STATUS from 'src/graphql/mutations/accessControlledCollection'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import GET_SINGLE_COLLECTION from 'src/graphql/queries/getSingleCollection'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import BookmarkAddNewModal from '../BookmarkAddNewModal'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { useAuth } from 'src/utils/auth'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import { fetchAllStoreProducts, setStoreProductState } from 'src/store/slices/fetchAllStoresSlice'
import BookMarkProductCard from 'src/components/BookmarkMain/AllbookmarksDetails/BookMarkProductCard'

interface StoreProduct {
  id: number;
  is_liked?: boolean;
  [key: string]: any;  // For other properties
}

interface BookmarkItem {
  products: StoreProduct;
  [key: string]: any;
}

interface BookmarkData {
  getAllBookmark?: {
    data?: BookmarkItem[];
  };
}

export default function CollectionByProductModal() {
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)
  const { user }: any = useAuth()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allStores = useSelector((state: any) => state?.storesData)
  const [page, setPage] = useState(1)
  const [dataLimite, setDataLimite] = useState(40)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setStoreProductState([]))
  }, [])

  const getBookmarkProduct = () => {
    dispatch(
      fetchAllStoreProducts({
        slug: isDesktop ? getAllState?.lastModalData?.item : fetchMobileModal?.lastModalData?.id,
        page: 1,
        limit: 10,
      }),
    )
  }

  useEffect(() => {
    getBookmarkProduct()
  }, [page])

  const dropdownRef: any = useRef()
  const [accountStatusDropdown, setAccountStatusDropdown] = useState(false)
  const [showCollectionModal, setShowCollectionModal] = useState(false)
  const [limit, setLimit] = useState(5)

  const likeData = useQuery(GET_SINGLE_COLLECTION_LIKE, {
    variables: {
      slug: isDesktop ? getAllState?.lastModalData?.id?.post?.collection?.slug : fetchMobileModal?.lastModalData?.id,
      userSlug: isDesktop ? getAllState?.lastModalData?.userName : fetchMobileModal?.lastModalData?.id,
    },
  })

  const colletionData = useQuery(GET_SINGLE_COLLECTION, {
    variables: {
      slug: isDesktop ? getAllState?.lastModalData?.id?.post?.collection?.slug : fetchMobileModal?.lastModalData?.id,
      userSlug: isDesktop ? getAllState?.lastModalData?.userName : fetchMobileModal?.lastModalData?.id,
    },
  })

  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK, {
    variables: {
      page: page,
      limit: dataLimite,
      slug: getAllState?.lastModalData?.id?.post?.collection?.slug,
      userSlug: getAllState?.lastModalData?.userName,
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  const handleShareCollection = () => {
    if (user) {
      dispatch(
        fetchProductImageResizeModal({
          modal: isDesktop ? 'mobileProductDetailsShareModal' : '',
          isOpen: true,
          isShareBookmark: true,
          shareData: data?.getAllBookmark?.data,
          detail: {
            slug: getAllState?.lastModalData?.id?.post?.collection?.slug,
            user_slug: getAllState?.lastModalData?.userName,
          },
        }),
      )
    } else {
      handleRedirect()
    }
  }

  const handleRemoveBookmark = () => {
    if (user) {
      dispatch(
        manageBookmark({
          modal: '',
          isOpen: false,
          id: {
            ...bookmarkTabData?.id,
            deleteCollection: {
              ...bookmarkTabData?.id?.deleteCollection,
              isShowDeleteModel: true,
              collectionId: getAllState?.lastModalData?.id?.post?.collection?.slug,
              collectionName: colletionData?.data?.getSingleCollection?.data?.name,
            },
          },
        }),
      )
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleEditCollection = () => {
    setShowCollectionModal(true)
  }

  const handleLikeCollection = () => {
    if (user) {
      addLikeCollection({
        variables: {
          collectionId: isDesktop
            ? getAllState?.lastModalData?.id?.post?.collection?.id
            : colletionData?.data?.getSingleCollection?.data?.id,
        },
        // refetchQueries: [
        //   {
        //     query: MY_FEED_QUERY,
        //     variables: {
        //       page: 1,
        //       limit: 10,
        //     },
        //   },
        // ],
      })
    } else {
      handleRedirect()
    }
  }

  const [addLikeCollection] = useMutation(ADD_COLLECTION_LIKE, {
    onCompleted: (res) => {
      likeData.refetch()
      colletionData.refetch()
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleChangeStatus = (name: string) => {
    changeCollectionStatus({
      variables: {
        slug: isDesktop ? getAllState?.lastModalData?.id?.post?.collection?.slug : fetchMobileModal?.lastModalData?.id,
        status: name,
      },
    })
  }

  const [changeCollectionStatus] = useMutation(CHANGE_COLLECTION_STATUS, {
    onCompleted: (res) => {
      likeData.refetch()
    },
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    getBookmarkProduct()
    likeData.refetch()
    colletionData.refetch()
  }, [getAllState, fetchMobileModal])

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (accountStatusDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setAccountStatusDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [accountStatusDropdown])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!colletionData?.data?.getSingleCollection?.success) {
    return (
      <div className={styles.bookMarkProductEmptyCartDetails}>
        {/* <div>
          <Lottie loop={false} animationData={emptyCart} play />
        </div> */}
        <div className={styles.bookMarkMobileViewImg}>
          <img src="/assets/icon/no-bookmark-img.svg" alt="no bookmark icon" />
        </div>
        <p>You have not Permission to Access this Collection.</p>
      </div>
    )
  }

  return (
    <div className={styles.CollectionByProductBox}>
      <div className={styles.bookMarkProductMainFlexAlignment}>
        <div className={styles.bookMarkModalProductNameAlignment}>
          <h6>{colletionData?.data?.getSingleCollection?.data?.name}</h6>
          <div className={styles.bookMarkHeaderOption}>
            {colletionData?.data?.getSingleCollection?.data?.user?.id === user?.id && (
              <div className={styles.editIcon} onClick={() => handleEditCollection()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 20H21" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className={styles.bookMarkModalSideOption}>
          {user && colletionData?.data?.getSingleCollection?.data?.user?.id === user?.id && (
            <div className={classNames(styles.optionBox, styles.webViewIcon)} onClick={() => handleRemoveBookmark()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M3 6H5H21" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M10 11V17" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 11V17" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          )}
          {allStores?.getAllStoreProduct?.getAllProducts?.data?.length > 0 && (
            <>
              <div className={classNames(styles.optionBox, styles.webViewIcon)} onClick={() => handleShareCollection()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.58984 13.5098L15.4198 17.4898"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.4098 6.50977L8.58984 10.4898"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={styles.optionBox} onClick={() => handleLikeCollection()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill={likeData?.data?.getCollectionSingleLike?.isLike ? '#ff0000' : 'none'}
                >
                  <path
                    d="M11.7655 21.7124L12 21.7896L12.2345 21.7124C14.1162 21.093 16.7139 19.4947 18.8429 17.365C20.9639 15.2434 22.75 12.4624 22.75 9.44773C22.75 6.0181 19.945 3.25 16.5 3.25C14.7336 3.25 13.1369 3.97736 12 5.14669C10.8631 3.97736 9.26642 3.25 7.5 3.25C4.05499 3.25 1.25 6.0181 1.25 9.44773C1.25 12.4624 3.03607 15.2434 5.15709 17.365C7.28615 19.4947 9.88377 21.093 11.7655 21.7124Z"
                    stroke={likeData?.data?.getCollectionSingleLike?.isLike ? '#ff0000' : '#7A7E88'}
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
            </>
          )}
          <div
            className={classNames(styles.optionBox, styles.mobileViewIcon)}
            onClick={() => {
              dispatch(
                fetchMobileModalData({
                  allModalData: [
                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                    {
                      modal: 'mobileViewBookMarkMoreOptionModal',
                      isOpen: true,
                      id: fetchMobileModal?.lastModalData?.id,
                      transform: 211,
                    },
                  ],
                  lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
                }),
              )
              handleShareCollection()
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="#7A7E88"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                stroke="#7A7E88"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                stroke="#7A7E88"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* <h6></h6>
      <h6></h6> */}
      <div className={styles.bookmarkAllProductNumberAlignment}>
        <span>{colletionData?.data?.getSingleCollection?.data?.product_count} Products</span>
        <p></p>
        <span>{colletionData?.data?.getSingleCollection?.data?.likes} likes</span>
      </div>
      {Boolean(data?.getAllBookmark?.data?.length) ? (
        <div className={styles.allBookMarkDetailsBodyAlignment}>
          {data?.getAllBookmark?.data?.length > 0 ? (
            <div className={styles.allBookMarkDetailsGrid}>
              {data?.getAllBookmark?.data?.map((item: BookmarkItem, index: number) => {
                return (
                  <BookMarkProductCard
                    item={item?.products}
                    index={index}
                    isInCollection={false}
                    handleRedirect={handleRedirect}
                    normalPostLikeHandler={() => {}}
                    bookmarkProduct={() => {}}
                    handleAddToCollection={() => {}}
                    isFilter={true}
                  />
                )
              })}
            </div>
          ) : (
            <div className={styles.noDataBookMarkCollectionDetails}>
              <img src="/assets/icon/no-bookmark-product-data.svg" alt="no data" />
              <p>No item saved on bookmark.</p>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.bookMarkProductEmptyCartDetails}>
          {/* <div>
            <Lottie loop={false} animationData={emptyCart} play />
          </div> */}
          <div className={styles.bookMarkMobileViewImg}>
            <img src="/assets/icon/no-bookmark-img.svg" alt="no bookmark icon" />
          </div>
          <p>No public collection yet. Be the first to share your collection!</p>
        </div>
      )}
      {showCollectionModal && (
        <BookmarkAddNewModal
          setAddCollectionWebModal={setShowCollectionModal}
          isEditCollection={true}
          name={colletionData?.data?.getSingleCollection?.data?.name}
          collectionId={
            isDesktop ? getAllState?.lastModalData?.id?.post?.collection?.slug : fetchMobileModal?.lastModalData?.id
          }
        />
      )}
    </div>
  )
}
