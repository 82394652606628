import React, { useEffect, useRef, useState } from 'react'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import Link from 'next/link'
import client1 from 'src/utils/apolloClient'

export default function HeaderCategoryMenu() {
  let subcategoryJewelry = ['Women', 'Men']

  const filterRef: any = useRef()
  const [menuOpen, setMenuOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileAllCategoryOpen, setMobileAllCategoryOpen] = useState(false)
  const [hoverData, setHoverData] = useState<any>(null)
  const [category, setCategory] = useState<any>([])
  const [selectedMenuData, setSelectedMenuData] = useState<any>(null)
  const [subCategoryData, setSubCategoryData] = useState<any>(null)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (filterRef.current && !filterRef.current.contains(e.target)) {
        setMobileMenuOpen(false)
      }
      selectedMenuData
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [filterRef])

  useEffect(() => {
    getCategory()
  }, [])

  const getCategory = async () => {
    const data: any = await client1.query({
      query: GET_CATEGORIES_QUERY,
    })
    setData(data?.data)
  }

  const handleHover = (data) => {
    setHoverData(data)
  }

  useEffect(() => { 
    if (Boolean(data?.getAllCategoryDetail?.length)) {
      setHoverData(data?.getAllCategoryDetail[0])
      setCategory(data?.getAllCategoryDetail)
    }
  }, [data])

  const selectIndex = (data) => {
    if (data?.id === selectedMenuData?.id) {
      setSelectedMenuData(null)
    } else {
      setSelectedMenuData(data)
    }
  }

  const handleSubCategory = (data) => {
    setSubCategoryData(data)
  }

  return (
    <>
      <div
        className="dashicons-menu-wrapper"
        onMouseLeave={(e) => {
          setMenuOpen(false)
        }}
      >
        <div className="dashicons-all-icon-alignment">
          <div className="dashicon-main-icon-alignment">
            <div
              className={menuOpen ? 'home-alignment activeShop' : 'home-alignment '}
              onMouseEnter={(e) => {
                setMenuOpen(true)
              }}
            >
              {/* <img src="/assets/seller/icons/shop-icon.svg" alt="shop-icon" loading="lazy" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect x="3" y="17" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="3" y="10" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="3" y="3" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="10" y="17" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="10" y="10" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="10" y="3" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="17" y="17" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="17" y="10" width="4" height="4" rx="1" fill="#7A7E88" />
                <rect x="17" y="3" width="4" height="4" rx="1" fill="#7A7E88" />
              </svg>
              <div className="home-icon-bottom-alignment"></div>
            </div>
          </div>

          <div
            className={mobileMenuOpen ? 'mobile-home-alignment activeShop' : 'mobile-home-alignment '}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {/* <img src="/assets/seller/icons/shop-icon.svg" alt="shop-icon" loading="lazy" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="3" y="17" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="3" y="10" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="3" y="3" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="10" y="17" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="10" y="10" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="10" y="3" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="17" y="17" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="17" y="10" width="4" height="4" rx="1" fill="#7A7E88" />
              <rect x="17" y="3" width="4" height="4" rx="1" fill="#7A7E88" />
            </svg>
            <div className="mobile-home-icon-bottom-alignment"></div>
          </div>
          <div
            className={
              menuOpen
                ? 'dashboard-main-wrapper-alignment open-main-wraaper'
                : 'dashboard-main-wrapper-alignment close-main-wraaper'
            }
            onMouseEnter={(e) => {
              setMenuOpen(false)
            }}
          ></div>
          <div
            className={
              menuOpen ? 'dashbord-menu-box-alignment open-menu-box' : 'dashbord-menu-box-alignment close-menu-box'
            }
            onMouseLeave={(e) => {
              e.stopPropagation()
              setMenuOpen(true)
            }}
          >
            <div className="dashboard-border-alignment">
              <div className="dashboard-menu-grid-alignment">
                <div className="dashboard-menu-grid-item">
                  <div className="dashboard-menu-sidebar-alignment">
                    {category?.map((cat, index) => {
                      return hoverData?.name === 'Beauty & Cosmetics' ? (
                        <React.Fragment key={index}>
                          <div className="dashboard-menu-option" onMouseEnter={() => handleHover(cat)} key={index}>
                            <Link
                              href={{
                                pathname: `/beauty-cosmetics`,
                              }}
                              scroll={false}
                            >
                              <>
                                <p>{cat?.name}</p>
                                <div className="dashboard-menu-icon">
                                  <img src="/assets/icon/rightArrowIcon.svg" alt="right arrow" />
                                </div>
                              </>
                            </Link>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="dashboard-menu-option" onMouseEnter={() => handleHover(cat)} key={index}>
                          <p>{cat?.name}</p>
                          <div className="dashboard-menu-icon">
                            <img src="/assets/icon/rightArrowIcon.svg" alt="right arrow" />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="dashboard-menu-grid-item">
                  <div className="dashboard-right-side-alignement">
                    <div className="right-side-top-details-alignment">
                      <div className="right-heading-alignment">
                        <h6>All in {hoverData?.name}</h6>
                        <div className="header-icon">
                          <img src="/assets/icon/second-right-arrow.svg" alt="right arrow" />
                        </div>
                      </div>
                      <div className="right-side-menu-details-alignment">
                        <div className="right-side-menu-grid">
                          {hoverData?.name === 'Beauty & Cosmetics' && (
                            <div className="right-side-menu-grid-item">
                              <div className="all-menu-list-alignment">
                                <Link href="/beauty-cosmetics" scroll={false}>
                                  <h6>Shop All Beauty</h6>
                                </Link>
                              </div>
                            </div>
                          )}
                          {hoverData?.subCategory.slice(0, 5)?.map((sub_cat, index) => {
                            return (
                              <div className="right-side-menu-grid-item" key={index}>
                                <div className="all-menu-list-alignment">
                                  <Link
                                    href={{
                                      pathname:
                                        hoverData?.name === 'Fashion & Accessories'
                                          ? sub_cat?.name === "Women's Accessories"
                                            ? '/fashion/women/accessories'
                                            : sub_cat?.name === "Men's Accessories"
                                            ? '/fashion/men/accessories'
                                            : `/fashion/${sub_cat?.name?.toLowerCase()}`
                                          : hoverData?.name === 'Jewelry'
                                          ? `/jewelry/${subcategoryJewelry[index]?.toLowerCase()}`
                                          : `/beauty-cosmetics/${sub_cat?.slug}`,
                                    }}
                                    scroll={false}
                                  >
                                    <h6>
                                      {hoverData?.name === 'Fashion & Accessories' ? sub_cat?.name : sub_cat?.name}
                                    </h6>
                                  </Link>
                                  <div className="all-child-menu-list-alignment">
                                    {sub_cat?.childSubCategory.slice(0, 5)?.map((child_sub, idx) => {
                                      return (
                                        <React.Fragment key={idx}>
                                          <Link
                                            href={{
                                              pathname:
                                                hoverData?.name === 'Fashion & Accessories'
                                                  ? sub_cat?.name === "Women's Accessories"
                                                    ? `/fashion/${'women-accessories'}/${child_sub?.slug}`
                                                    : sub_cat?.name === "Men's Accessories"
                                                    ? `/fashion/${'men-accessories'}/${child_sub?.slug}`
                                                    : `/fashion/${sub_cat?.name?.toLowerCase()}/${child_sub?.slug}`
                                                  : hoverData?.name === 'Jewelry'
                                                  ? `/jewelry/${sub_cat?.name?.toLowerCase()}/${child_sub?.slug}`
                                                  : `/beauty-cosmetics/${sub_cat?.slug}`,
                                              // query: { category: child_sub.name },
                                            }}
                                            scroll={false}
                                          >
                                            <a>
                                              <p>{child_sub?.name}</p>
                                            </a>
                                          </Link>
                                        </React.Fragment>
                                      )
                                    })}
                                    {sub_cat?.childSubCategory?.length > 5 && <a className="link-alignment">More</a>}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    {/* <div className="right-side-bottom-details-alignment">
                      <div className="right-side-heading-alignment">
                        <h6>Recently Viewed in Fashion</h6>
                      </div>
                      <div className="bottom-imgs-alignment">
                        <div className="bottom-imgs-grid">
                          <div className="bottom-imgs-grid-item">
                            <img src="/assets/icon/hdemo-img.png" alt="demo img" />
                          </div>
                          <div className="bottom-imgs-grid-item">
                            <img src="/assets/icon/hdemo-img2.png" alt="demo img" />
                          </div>
                          <div className="bottom-imgs-grid-item">
                            <img src="/assets/icon/hdemo-img3.png" alt="demo img" />
                          </div>
                          <div className="bottom-imgs-grid-item">
                            <img src="/assets/icon/hdemo-img4.png" alt="demo img" />
                          </div>
                          <div className="bottom-imgs-grid-item">
                            <img src="/assets/icon/hdemo-img5.png" alt="demo img" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              mobileMenuOpen
                ? 'mobile-view-catgory-wrpper-alignment open-wrapper-alignmenbt'
                : 'mobile-view-catgory-wrpper-alignment close-wrapper-alignmenbt'
            }
          >
            <div
              className={
                mobileMenuOpen
                  ? 'mobile-view-category-box-alignment open-category-box-alignmenbt'
                  : 'mobile-view-category-box-alignment close-category-box-alignmenbt'
              }
            >
              {category?.map((cat, index) => {
                return (
                  <div className="mobile-view-main-category-alignment" key={index}>
                    <div className="mobile-view-link-alignment" onClick={() => selectIndex(cat)}>
                      <h4>{cat?.name}</h4>
                      <div
                        className={
                          selectedMenuData?.id === cat?.id ? 'active-dropdown-icon ' : 'deactive-dropdown-icon'
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="56" viewBox="0 0 33 56" fill="none">
                          <path
                            d="M23 25L17 31L11 25"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className={
                        selectedMenuData?.id === cat?.id
                          ? 'mobile-view-child-details-alignment open-child-category-detils'
                          : 'mobile-view-child-details-alignment close-child-category-detils'
                      }
                    >
                      {cat?.subCategory?.map((subcat, i) => {
                        return (
                          <div
                            className="mobile-view-child-link-alignment"
                            onClick={() => {
                              handleSubCategory(subcat)
                            }}
                            key={i}
                          >
                            <Link
                              href={{
                                pathname:
                                  selectedMenuData?.name === 'Fashion & Accessories'
                                    ? subcat?.name === "Women's Accessories"
                                      ? '/fashion/women/accessories'
                                      : subcat?.name === "Men's Accessories"
                                      ? '/fashion/men/accessories'
                                      : `/fashion/${subcat?.name?.toLowerCase()}`
                                    : selectedMenuData?.name === 'Jewelry'
                                    ? `/jewelry/${subcat?.name?.toLowerCase()}`
                                    : `/beauty-cosmetics`,
                                // query: { category: child_sub.name },
                                // ...(selectedMenuData?.name === 'Beauty & Cosmetics' && {
                                //   query: { category: subcat?.name },
                                // }),
                              }}
                              scroll={false}
                            >
                              <h6
                                onClick={() => {
                                  setMobileMenuOpen(false)
                                }}
                              >
                                {cat?.name === 'Fashion & Accessories' ? subcat?.name : subcat?.name}
                              </h6>
                            </Link>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              onClick={() => setMobileAllCategoryOpen(true)}
                            >
                              <path
                                d="M1 13L7 7L1 1"
                                stroke="#1E2432"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div
            className={
              mobileAllCategoryOpen
                ? 'second-mobile-view-modal-details-alignment open-second-modal'
                : 'second-mobile-view-modal-details-alignment close-second-modal'
            }
          >
            <div className="second-mobile-heading-alignment">
              <div onClick={() => setMobileAllCategoryOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="44" viewBox="0 0 24 44" fill="none">
                  <path
                    d="M15 27L11 22L15 17"
                    stroke="#1E2432"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h6>{subCategoryData?.name}</h6>
              <a>All in {subCategoryData?.name}</a>
            </div>
            <div className="second-mobile-modal-body-alignment">
              <div className="second-mobile-modal-body-grid">
                {subCategoryData?.childSubCategory?.map((childsub, index) => {
                  return (
                    <div className="second-mobile-modal-body-grid-item" key={index}>
                      <Link
                        href={{
                          pathname:
                            selectedMenuData?.name === 'Fashion & Accessories'
                              ? `/fashion/${subCategoryData?.slug}/${childsub?.slug}`
                              : selectedMenuData?.name === 'Jewelry'
                              ? `/jewelry/${subCategoryData?.slug}/${childsub?.slug}`
                              : `/beauty-cosmetics`,
                        }}
                        scroll={false}
                      >
                        <a
                          onClick={() => {
                            setMobileMenuOpen(false)
                            setMobileAllCategoryOpen(false)
                          }}
                        >
                          <div className="second-mobile-img">
                            <img src={childsub?.media} alt="category img" />
                          </div>
                          <p>{childsub?.name}</p>
                        </a>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
