import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_ALL_BOOKMARK from 'src/graphql/queries/getAllBookmark'
import GET_ALL_PRODUCTS from 'src/graphql/queries/getAllProducts'
import GET_SINGLE_STORE_LIKE from 'src/graphql/queries/getSingleStoreLike'
import GET_ALL_STORE from 'src/graphql/queries/getStores'
import GET_POPULAR_STORES from 'src/graphql/queries/sellerQueries/getAllPopularStores'
import client1 from 'src/utils/apolloClient'

interface fetchAllStore {
  loading: true | false
  error: {} | null
  called: true | false
  allPost: []
  groupPosts: []
  categoryStore: []
  userPosts: []
  storeProductState: []
}

const initialState = {
  loading: true,
  error: null,
  currentStep: '',
  allPost: [],
  groupPosts: [],
  userPosts: [],
  categoryStore: [],
  called: false,
  storeProductState: [],
  store_total_count: 0,
} as fetchAllStore

export const fetchFollowedStoreData = createAsyncThunk('fetchStores/fetchFollowedStoreData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_STORE,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllStore?.data?.stores
})

export const fetchPopularStoreData = createAsyncThunk('fetchStores/fetchPopularStoreData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_STORE,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
      search: post?.search,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllStore?.data?.stores
})

export const fetchSuggestedStoreData = createAsyncThunk('fetchStores/fetchSuggestedStoreData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_STORE,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
      search: post?.search,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllStore?.data?.stores
})

export const fetchAllStoreData = createAsyncThunk('fetchStores/fetchAllStoreData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_STORE,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
      search: post?.search,
    },
    fetchPolicy: 'network-only',
  })
  // return data?.getAllStore?.data?.stores
  return { store: [...post?.prevPost, ...data?.getAllStore?.data?.stores], count: data?.getAllStore?.data?.count }
})

export const fetchAllStoreProducts = createAsyncThunk('fetchStores/fetchAllStoreProducts', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_PRODUCTS,
    variables: {
      storeId: post?.store_id,
      page: post?.page,
      limit: post?.limit,
      search: '',
      status: '',
      subCategoryName: post?.subCategory,
      categoryName: post?.category,
      maxPrice: post?.maxPrice,
      minPrice: post?.minPrice,
    },
    fetchPolicy: 'network-only',
  })
  return data
})

export const fetchAllBookmarkProducts = createAsyncThunk('fetchStores/fetchAllBookmarkProducts', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_BOOKMARK,
    variables: {
      slug: post?.slug,
      page: post?.page,
      limit: post?.limit,
    },
    fetchPolicy: 'network-only',
  })
  return []
})

export const fetchSingleStoreLikes = createAsyncThunk('fetchStores/fetchSingleStoreLikes', async (post: any) => {
  const { data } = await client1.query({
    query: GET_SINGLE_STORE_LIKE,
    variables: {
      storeId: post?.storeId,
    },
    fetchPolicy: 'network-only',
  })
  return data
})

export const fetchStoreByCategory = createAsyncThunk('fetchStores/fetchStoreByCategory', async (categoryData: any) => {
  const { category_id, subCategory_id, page, limit } = categoryData
  const { data } = await client1.query({
    query: GET_POPULAR_STORES,
    variables: {
      category: category_id,
      subCategory: subCategory_id,
      page: page,
      limit: limit,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllPopularStores
})

const fetchAllStore = createSlice({
  name: 'fetchStores',
  initialState,
  reducers: {
    setJoinedStoreState: (state: any, action) => {
      state.getAllStore = action.payload
    },

    setStoreProductState: (state: any, action) => {
      state.getAllStoreProduct = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFollowedStoreData.pending, (state, action) => {
      // state.loading = 'loading'
    })
    builder.addCase(fetchFollowedStoreData.fulfilled, (state, action) => {
      // state.loading = 'success'
      state.allStoreData = action?.payload
    })
    builder.addCase(fetchFollowedStoreData.rejected, (state, action) => {
      // state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchPopularStoreData.pending, (state, action) => {
      // state.loading = 'loading'
    })
    builder.addCase(fetchPopularStoreData.fulfilled, (state, action) => {
      // state.loading = 'success'
      state.allPopularStores = action?.payload
    })
    builder.addCase(fetchPopularStoreData.rejected, (state, action) => {
      // state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchSuggestedStoreData.pending, (state, action) => {
      // state.loading = 'loading'
    })
    builder.addCase(fetchSuggestedStoreData.fulfilled, (state, action) => {
      // state.loading = 'success'
      state.allSuggestedStores = action?.payload
    })
    builder.addCase(fetchSuggestedStoreData.rejected, (state, action) => {
      // state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchAllStoreData.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(fetchAllStoreData.fulfilled, (state, action) => {
      state.loading = false
      state.store_total_count = action?.payload?.count
      state.getAllStore = action?.payload?.store
    })
    builder.addCase(fetchAllStoreData.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    builder.addCase(fetchAllStoreProducts.pending, (state, action) => {
      state.called = false
      // state.getAllStoreProduct = {
      //   getAllProductByStore: {
      //     data: [...Array(40)],
      //   },
      // }
    })
    builder.addCase(fetchAllStoreProducts.fulfilled, (state, action) => {
      state.called = true
      state.getAllStoreProduct = action?.payload
    })
    builder.addCase(fetchAllStoreProducts.rejected, (state, action) => {
      state.called = true
      state.error = action.error.message
    })

    builder.addCase(fetchAllBookmarkProducts.pending, (state, action) => {
      state.called = false
    })
    builder.addCase(fetchAllBookmarkProducts.fulfilled, (state, action) => {
      state.called = true
      state.getAllStoreProduct = action?.payload
    })
    builder.addCase(fetchAllBookmarkProducts.rejected, (state, action) => {
      state.called = true
      state.error = action.error.message
    })

    builder.addCase(fetchSingleStoreLikes.pending, (state, action) => {
      // state.loading = 'loading'
    })
    builder.addCase(fetchSingleStoreLikes.fulfilled, (state, action) => {
      // state.loading = 'success'
      state.getSingleStoreLike = action?.payload
    })
    builder.addCase(fetchSingleStoreLikes.rejected, (state, action) => {
      // state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchStoreByCategory.pending, (state, action) => {
      // state.loading = 'loading'
    })
    builder.addCase(fetchStoreByCategory.fulfilled, (state, action) => {
      // state.loading = 'success'
      state.categoryStore = action?.payload?.data?.stores
    })
    builder.addCase(fetchStoreByCategory.rejected, (state, action) => {
      // state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export var _actions = fetchAllStore.actions
export const { setJoinedStoreState, setStoreProductState } = fetchAllStore.actions
export default fetchAllStore.reducer
