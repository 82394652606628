import React, { useState } from 'react'
import BookMarkIcon from '../../../../../public/assets/icon/bookmark.svg'
import GridIcon from '../../../../../public/assets/icon/grid.svg'
import UserIcon from '../../../../../public/assets/icon/mobile-users.svg'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useMutation } from '@apollo/client'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'

export default function MobileViewProductCategory(props) {
  const { openMobileDropdown, setOpenMobileDropdown, selectedProductId } = props
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const router = useRouter()
  const [isCalling, setIsCalling] = useState(false)
  const [isBookMark, setIsBookMark] = useState(fetchMobileModal?.lastModalData?.id?.bookmark)

  const bookmarkProduct = (product) => {
    if (user) {
      dispatch(
        manageBookmark({
          modal: '',
          isOpen: false,
          id: {
            // ...bookmarkTabData?.id,
            // type: 'product',
            // store_id: data?.getSingleProduct?.store_id,
            // bookmark_id: store_follow_status?.data?.getStoreFollow?.bookmark_id,
            // collection_id: store_follow_status?.data?.getStoreFollow?.collection_id,
            product_id: Number(product?.id),
            addProductToCollection: {
              productId: Number(product?.id),
            },
            // addProductToCollection: {
            //   ...bookmarkTabData?.id?.deleteCollection,
            //   isShowAddCollectionModel: true,
            //   productId: Number(product_data?.id),
            // },
          },
        }),
      )

      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'bookmarkProductModal',
              isOpen: true,
              id: {
                isSeller: false,
                group: null,
                type: 'post',
                price: product?.dis_price,
                dis_price: product?.dis_price,
              },
              transform: 305,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
      // if (!isCalling) {
      //   if (product?.bookmark) {
      //     setIsCalling(true)
      //     removeBookmark({
      //       variables: {
      //         id: product?.bookmark?.id,
      //       },
      //       refetchQueries: [
      //         {
      //           query: GET_ALL_BOOKMARK,
      //           variables: {
      //             page: 1,
      //             limit: 10,
      //           },
      //         },
      //         {
      //           query: GET_ALL_PRODUCT_BY_STORE,
      //           variables: {
      //             store_id: fetchMobileModal?.lastModalData?.id?.storeData?.id,
      //             page: 1,
      //             limit: 10,
      //             search: '',
      //             status: '',
      //             subCategory: [],
      //           },
      //         },
      //       ],
      //     }).then((response) => {
      //       if (response?.data !== undefined) {
      //         setIsCalling(false)
      //         setIsBookMark(!isBookMark)
      //       }
      //     })
      //   } else {
      //     setIsCalling(true)
      //     addBookmark({
      //       variables: {
      //         productId: product?.id,
      //       },
      //       refetchQueries: [
      //         {
      //           query: GET_ALL_BOOKMARK,
      //           variables: {
      //             page: 1,
      //             limit: 10,
      //           },
      //         },
      //         {
      //           query: GET_ALL_PRODUCT_BY_STORE,
      //           variables: {
      //             store_id: fetchMobileModal?.lastModalData?.id?.storeData?.id,
      //             page: 1,
      //             limit: 10,
      //             search: '',
      //             status: '',
      //             subCategory: [],
      //           },
      //         },
      //       ],
      //     }).then((response) => {
      //       if (response?.data !== undefined) {
      //         setIsCalling(false)
      //         setIsBookMark(!isBookMark)
      //       }
      //     })
      //   }
      // }
    } else {
      handleRedirect()
    }
  }

  const [addBookmark] = useMutation(ADD_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            { modal: 'productOption', isOpen: false, id: null },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            { modal: 'productOption', isOpen: false, id: null },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  return (
    <React.Fragment>
      <div
        className={`follow-followingOverlay ${openMobileDropdown ? 'share-product' : ''}`}
        onClick={() => setOpenMobileDropdown(false)}
      >
        <div className="mobile-view-product-category-card-alignment">
          <div className="mobile-view-product-category-card-img-alignment">
            <div className="share-me-feed-img-alignment">
              {fetchMobileModal?.length > 0 && fetchMobileModal[0]?.includes('mp4') ? (
                <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                  <source src={fetchMobileModal[0]} />
                </video>
              ) : (
                <img src={fetchMobileModal[0]} alt="ProductImg" />
              )}
            </div>
          </div>
          <div className="mobile-view-product-category-details-allignment">
            <h4>
              {selectedProductId?.title?.length > 34
                ? selectedProductId?.title.substring(0, 34) + '...'
                : selectedProductId?.title}
            </h4>
            <div className="mobile-price-alignment">
              <p>$ {selectedProductId?.price}</p>
              <span>$ {selectedProductId?.listPrice}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-view-details-alignment">
        <div className="mobile-view-details-grid-alignment">
          <div className="mobile-view-details-icon-alignment">
            <img src={BookMarkIcon.src} alt="BookMarkIcon" />
          </div>
          <div
            className="mobile-view-child-details-alignment"
            onClick={() => bookmarkProduct(fetchMobileModal?.lastModalData?.id)}
          >
            <h5>Bookmark</h5>
            <p>Save this product for later</p>
          </div>
        </div>
        <div
          className="mobile-view-details-grid-alignment"
          onClick={() => {
            dispatch(
              fetchMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  {
                    modal: 'productShareOnMyFeed',
                    isOpen: true,
                    id: fetchMobileModal?.lastModalData?.id,
                    transform: 20,
                  },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }}
        >
          <div className="mobile-view-details-icon-alignment">
            <img src={GridIcon.src} alt="GridIcon" />
          </div>
          <div className="mobile-view-child-details-alignment">
            <h5>Share on my feed</h5>
            <p>Share this post on my feed</p>
          </div>
        </div>
        <div
          className="mobile-view-details-grid-alignment"
          onClick={() => {
            dispatch(
              fetchMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  {
                    modal: 'productSharewithFriend',
                    isOpen: true,
                    id: fetchMobileModal?.lastModalData?.id,
                    transform: 20,
                  },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }}
        >
          <div className="mobile-view-details-icon-alignment">
            <img src={UserIcon.src} alt="UserIcon" />
          </div>
          <div className="mobile-view-child-details-alignment">
            <h5>Share with Friends</h5>
            <p>Share post with friends</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
