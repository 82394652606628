import React, { useEffect, useState } from 'react'
import styles from './BookmarkCollectionDetailModal.module.scss'
import AllProduct from 'src/components/ProductPage/AllProduct'
import { useMutation, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import GET_SINGLE_COLLECTION from 'src/graphql/queries/getSingleCollection'
import GET_SINGLE_COLLECTION_LIKE from 'src/graphql/queries/getCollectionSingleLike'
import FOLLOW_UNFOLLOW_COLLECTION from 'src/graphql/mutations/followUnfollowCollection'
import ADD_COLLECTION_LIKE from 'src/graphql/mutations/addCollectionLike'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchAllStoreProducts, setStoreProductState } from 'src/store/slices/fetchAllStoresSlice'
import { setProductByCategoryState } from 'src/store/slices/fetchAllProduct'
const ProfileImg = '/assets/img/profile/profileimage.png'

interface StoreProduct {
  id: number;
  is_liked?: boolean;
  [key: string]: any;  // For other properties
}

interface StoreData {
  getAllStoreProduct?: {
    getAllProducts?: {
      data?: StoreProduct[];
      total?: number;
    };
  };
}

export default function BookmarkCollectionDetailModal() {
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const [page, setPage] = useState(1)
  const allStores: StoreData = useSelector((state: any) => state?.storesData)
  const [filterData, setFilterData] = useState<any>({ subCategory: [], category: [] })

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  useEffect(() => {
    dispatch(setStoreProductState([]))
    getBookmarkProduct()
  }, [])

  const getBookmarkProduct = (product_id = null, type = null) => {
    dispatch(
      fetchAllStoreProducts({
        slug: isDesktop ? getAllState?.lastModalData?.item : fetchMobileModal?.lastModalData?.id,
        page: 1,
        limit: 10,
      }),
    ).then(() => {
      if (type === 'dislike' || type === 'like') {
        let update_product = allStores?.getAllStoreProduct?.getAllProducts?.data?.map((prd) => {
          if (prd?.id === product_id) {
            return {
              ...prd,
              likes: type === 'like' ? { id: 3.3333 } : null,
            }
          } else {
            return prd
          }
        })
        dispatch(
          setProductByCategoryState({
            getAllProducts: {
              data: update_product,
            },
          }),
        )
      }
    })
  }

  const fetchUpdatedProduct = (product_id, type) => {
    getBookmarkProduct(product_id, type)
  }

  useEffect(() => {
    getBookmarkProduct()
  }, [])

  useEffect(() => {
    getBookmarkProduct()
  }, [page])

  const colletionData = useQuery(GET_SINGLE_COLLECTION, {
    variables: {
      slug: isDesktop ? getAllState?.lastModalData?.item : fetchMobileModal?.lastModalData?.id,
    },
  })

  const likeData = useQuery(GET_SINGLE_COLLECTION_LIKE, {
    variables: {
      slug: isDesktop ? getAllState?.lastModalData?.item : fetchMobileModal?.lastModalData?.id,
    },
  })

  const handleLikeCollection = () => {
    addLikeCollection({
      variables: {
        collectionId: isDesktop ? getAllState?.lastModalData?.item : colletionData?.data?.getSingleCollection?.data?.id,
      },
    })
  }

  const handleShareBookmark = () => {
    if (colletionData?.data?.getSingleCollection?.data?.product_count > 0) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'mobileViewBookMarkMoreOptionModal',
              isOpen: true,
              id: fetchMobileModal?.lastModalData?.id,
              transform: 420,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
        }),
      )

      dispatch(
        fetchProductImageResizeModal({
          modal: 'mobileProductDetailsShareModal',
          isOpen: false,
          isShareBookmark: true,
          shareData: allStores?.getAllStoreProduct?.getAllProducts?.data,
        }),
      )
    }
  }

  const [addLikeCollection] = useMutation(ADD_COLLECTION_LIKE, {
    onCompleted: (res) => {
      likeData.refetch()
      colletionData.refetch()
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleFollowCollection = () => {
    followUnfollowcollection({
      variables: {
        collectionId: getAllState?.lastModalData?.item,
      },
      refetchQueries: [
        {
          query: GET_SINGLE_COLLECTION_LIKE,
          variables: {
            collectionId: Number(getAllState?.lastModalData?.item),
          },
        },
      ],
    })
  }

  const [followUnfollowcollection] = useMutation(FOLLOW_UNFOLLOW_COLLECTION, {
    onCompleted: (res) => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const handleShareCollection = () => {
    dispatch(
      fetchProductImageResizeModal({
        modal: 'mobileProductDetailsShareModal',
        isOpen: true,
        isShareBookmark: true,
        shareData: allStores?.getAllStoreProduct?.getAllProducts?.data,
      }),
    )
  }

  useEffect(() => {
    getBookmarkProduct()
    colletionData.refetch()
    likeData.refetch()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={styles.bookMarkCollectionDetailsModalAlignment}>
      <div className={styles.bbokMarkCollectionHeading}>
        <div className={styles.bookMarkLeftDetails}>
          <h4>{colletionData?.data?.getSingleCollection?.data?.name}</h4>

          <div className={styles.bookMarkProfileCollection}>
            <div className={styles.bookMarkProfile}>
              <div className={styles.profileImg}>
                <img
                  src={
                    colletionData?.data?.getSingleCollection?.data?.user?.logo_image
                      ? colletionData?.data?.getSingleCollection?.data?.user?.logo_image
                      : ProfileImg
                  }
                  alt="ProfileImg"
                />
              </div>
              <p>
                {colletionData?.data?.getSingleCollection?.data?.user?.firstName +
                  ' ' +
                  colletionData?.data?.getSingleCollection?.data?.user?.lastName}
              </p>
            </div>

            <div className={styles.followButton} onClick={() => handleFollowCollection()}>
              <a>{likeData?.data?.getCollectionSingleLike?.isFollow ? 'Unfollow' : '+ follow'}</a>
            </div>
          </div>
        </div>

        <div className={styles.bookMarkRightSideAlignment}>
          <div className={styles.bookMarkIcon} onClick={() => handleShareCollection()}>
            {isDesktop && (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.58984 13.5098L15.4198 17.4898"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4098 6.50977L8.58984 10.4898"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <div
            className={classNames(styles.bookMarkIcon, {
              /* styles.webView*/
            })}
            onClick={() => handleLikeCollection()}
          >
            {likeData?.data?.getCollectionSingleLike?.isLike ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M11.7655 21.7124L12 21.7896L12.2345 21.7124C14.1162 21.093 16.7139 19.4947 18.8429 17.365C20.9639 15.2434 22.75 12.4624 22.75 9.44773C22.75 6.0181 19.945 3.25 16.5 3.25C14.7336 3.25 13.1369 3.97736 12 5.14669C10.8631 3.97736 9.26642 3.25 7.5 3.25C4.05499 3.25 1.25 6.0181 1.25 9.44773C1.25 12.4624 3.03607 15.2434 5.15709 17.365C7.28615 19.4947 9.88377 21.093 11.7655 21.7124Z"
                  fill="#E71616"
                  stroke="#E71616"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M11.7655 21.7124L12 21.7896L12.2345 21.7124C14.1162 21.093 16.7139 19.4947 18.8429 17.365C20.9639 15.2434 22.75 12.4624 22.75 9.44773C22.75 6.0181 19.945 3.25 16.5 3.25C14.7336 3.25 13.1369 3.97736 12 5.14669C10.8631 3.97736 9.26642 3.25 7.5 3.25C4.05499 3.25 1.25 6.0181 1.25 9.44773C1.25 12.4624 3.03607 15.2434 5.15709 17.365C7.28615 19.4947 9.88377 21.093 11.7655 21.7124Z"
                  fill="none"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                />
              </svg>
            )}
          </div>
          <div className={classNames(styles.bookMarkIcon, styles.mobileView)} onClick={() => handleShareBookmark()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="#7A7E88"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                stroke="#7A7E88"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                stroke="#7A7E88"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className={styles.bookMarkCollectionAllProductAlignment}>
        <div className={styles.bookMarkCollectionTop}>
          <span>{colletionData?.data?.getSingleCollection?.data?.product_count} Products</span>
          <p></p>
          <span>{colletionData?.data?.getSingleCollection?.data?.likes} likes</span>
        </div>

        {Boolean(allStores?.getAllStoreProduct?.getAllProducts?.data?.length) ? (
          <div className={styles.bookMarkCollectionAllDetails}>
            <AllProduct
              products={allStores?.getAllStoreProduct?.getAllProducts?.data}
              storeId={0}
              color={'#FFFFFF'}
              isFilterMenu={false}
              topForFilter={118}
              total={allStores?.getAllStoreProduct?.getAllProducts?.total}
              setFilterData={setFilterData}
              filterData={filterData}
              APIcall={fetchUpdatedProduct}
            />
          </div>
        ) : (
          <div className={styles.bookMarkProductEmptyCartDetails}>
            {/* <div>
              <Lottie loop={false} animationData={emptyCart} play />
            </div> */}
            <p>
              No public collection yet. <br /> Be the first to share your collection!
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
