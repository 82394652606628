import React, { useEffect } from 'react'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import Avatar from 'src/components/shared/Avatar'
import { Spin } from 'antd'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { fetchStoreFollowers } from 'src/store/slices/fetchAllFeedSlice'
import { useAppDispatch } from 'src/store/store'

const StorePopOver = (props) => {
  const { name, slug, id, buttonHandler1, buttonHandler2 } = props
  const router = useRouter()
  const dispatch = useAppDispatch()
  const storeData = useQuery(
    gql`
    query GET_STORE_BY_NAME($slug: String!) {
      getSingleStore(slug: $slug) {
        success
        message
        data {
          id
          name
          # logo
          # cover_image
          logo_image
          banner_image
          streetAddress
          city
          state
          postalCode
          createdAt
          isExist
          product_count
          longDescription
        }
      }
    }
    `,
    {
      variables: { slug: slug },
    },
  )
  
  const allFollowers = useSelector((state: any) => state?.feedData?.storeFollowers)

  useEffect(() => {
    dispatch(fetchStoreFollowers(slug))
  }, [])

  let profile = storeData?.data?.getSingleStore?.data?.logo_image
  // let profile_back =

  let showDetail1 =
    storeData?.data?.getSingleStore?.products?.length > 0
      ? storeData?.data?.getSingleStore?.products?.map((prod) => {
          return {
            user: {
              profileUrl: prod?.image && prod?.image[0],
            },
          }
        })
      : []
  let showDetail2 = storeData?.data?.getSingleStore?.followers
  let isFollow = storeData?.data?.getSingleStore?.data?.isExist ? true : false
  let showDetailName1 = 'Products'
  let showDetailName2 = 'Followers'
  let isGroup = false

  const shadowstyle = {
    boxShadow: '0px 5px 50px rgba(163, 156, 169, 0.15)',
  }

  const pouoDescription = {
    padding: '2px 28px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: ' #808191',
    height: '48px',
  }

  const followingTextStyle = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#808191',
  }

  let bio_detail =
    storeData?.data?.getSingleStore?.data?.longDescription

  return (
    <React.Fragment>
      {storeData?.loading ? (
        <div className="flex ">
          <div className="m-auto spin-design-popover">
            <Spin tip="loading" />
          </div>
        </div>
      ) : (
        <div className="-m-8 relative" style={shadowstyle}>
          {storeData?.data?.getSingleStore?.data?.banner_image ? (
            <img
              src={storeData?.data?.getSingleStore?.data?.banner_image}
              className="rounded-t-xl h-36 relative z-10 object-cover"
              style={{ width: '330px' }}
            />
          ) : (
            <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
              <Skeleton className={'rounded-t-xl h-36 relative z-10 object-cover'} style={{ width: '330px' }} />
            </SkeletonTheme>
          )}
          <div className="relative w-300 rounded-b-xl -mt-1" style={{ background: '#ECEEF4', width: '330px' }}>
            <div className="absolute left-10 z-20 bg-white rounded-full" style={{ top: '-20px' }}>
              <Avatar
                radius={44}
                border={0}
                avatarlist={[
                  {
                    id: 1,
                    avatarURL: profile,
                  },
                ]}
              />
            </div>
            <div className="bg-white" style={{ height: '98px' }}>
              <h5
                className="text-sm text-black font-medium text-center -ml-7 hover:underline cursor-pointer"
                style={{ paddingTop: '4px', marginLeft: '-73px' }}
                onClick={() => router.push(`/store/${slug}`)}
              >
                {name}
              </h5>
              <p className="popup-description" style={pouoDescription}>
                {bio_detail ? bio_detail?.length > 75 ? bio_detail?.substring(0, 75) + '...' : bio_detail : 'No description available'}
              </p>
              <div className="flex justify-end items-end -mt-4" style={{ padding: '0 12px' }}>
                {isFollow ? (
                  <div>
                    <div onClick={() => buttonHandler2()}>
                      <button className="rounded-full bg-blue w-28 h-10 text-white text-xs mb-3">Following</button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div onClick={() => buttonHandler1()}>
                      <button className="rounded-full bg-blue w-28 h-10 text-white text-xs mb-3">Follow</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="" style={{ padding: '0px 18px' }}>
              <div className="flex justify-between text-base items-center" style={{ height: '44px' }}>
                {!isGroup && (
                  <div className="flex items-center gap-2">
                    <div className="font-medium text-center">{storeData?.data?.getSingleStore?.data?.product_count}</div>
                    <span style={followingTextStyle}> {showDetailName1}</span>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <div className="font-medium text-center">{allFollowers?.length}</div>
                  <span style={followingTextStyle}> {showDetailName2}</span>
                </div>
                <div className="flex">
                  <Avatar
                    avatarlist={
                      allFollowers?.length > 0
                        ? allFollowers.slice(0, 5).map((prod) => {
                            return {
                              id: prod?.user?.id,
                              avatarURL: prod?.user?.profileUrl,
                            }
                          })
                        : []
                    }
                    radius={20}
                    border={2}
                  />
                  {allFollowers?.length > 5 && (
                    <div
                      className="rounded-full text-white text-8 -ml-4 pt-1 bg-blue border-2 border-white pb-2 pr-1"
                      style={{ width: 20, height: 20, zIndex: 999 }}
                    >
                      <span style={{ paddingLeft: '3px' }}>+{allFollowers?.length - 5}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default StorePopOver
