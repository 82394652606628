import React, { useEffect, useState } from 'react'
import styles from './NewArrival.module.scss'
import { fetchProductByCategory } from 'src/store/slices/fetchAllProduct'
import { useAppDispatch } from 'src/store/store'
import BookMarkProductCard from 'src/components/BookmarkMain/AllbookmarksDetails/BookMarkProductCard'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import Newartivaltitle from '../Arrivaltitle'
import { useSelector } from 'react-redux'

export default function NewArrival() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  // const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const [products, setProducts] = useState<any>([])

  useEffect(() => {
    dispatch(
      fetchProductByCategory({
        category: getAllState?.lastModalData?.id?.category,
        subCategory: getAllState?.lastModalData?.id?.subCategory,
        childSubCategory: getAllState?.lastModalData?.id?.childSubCategory,
        isShopifyProduct: getAllState?.lastModalData?.id?.isShopifyProduct,
        page: getAllState?.lastModalData?.id?.page,
        limit: getAllState?.lastModalData?.id?.limit,
        imagelimit: 1,
      }),
    ).then((res) => {
      setProducts(res?.payload?.getAllProducts?.data)
    })
  }, [getAllState?.lastModalData?.id])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const normalPostLikeHandler = () => {}

  const bookmarkProduct = () => {}

  const handleAddToCollection = () => {}

  return (
    <>
      <Newartivaltitle />
      <div className={styles.allBookMarkDetailsBodyAlignment}>
        {products?.length ? (
          <div className={styles.scrollableContainer}>
            <div className={styles.allBookMarkDetailsGrid}>
              {products?.map((item: any, index: number) => {
                return (
                  <BookMarkProductCard
                    item={item}
                    index={index}
                    isInCollection={false}
                    handleRedirect={handleRedirect}
                    normalPostLikeHandler={normalPostLikeHandler}
                    bookmarkProduct={bookmarkProduct}
                    handleAddToCollection={handleAddToCollection}
                    isFilter={true}
                  />
                )
              })}
            </div>
          </div>
        ) : (
          <div className={styles.noDataBookMarkCollectionDetails}>
            <img src="/assets/icon/no-bookmark-product-data.svg" alt="no data" />
            <p>No Product Found.</p>
          </div>
        )}
      </div>
    </>
  )
}
