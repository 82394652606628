import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_ALL_PEOPLE from 'src/graphql/queries/getAllPeople'
import client1 from 'src/utils/apolloClient'

interface fetchAllPeopleSlice {
  loading: true | false
  error: {} | null
  called: true | false
  allPeopleData: []
}

const initialState = {
  loading: true,
  error: null,
  called: false,
  allPeopleData: [],
} as fetchAllPeopleSlice

export const fetchAllPeople = createAsyncThunk('fetchPeople/fetchAllPeople', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_PEOPLE,
    variables: {
      page: post?.page,
      limit: post?.limit,
      type: post?.type,
      search: post?.search,
      gender: post?.gender,
    },
    fetchPolicy: 'network-only',
  })
  return { data: [...post?.prevPost, ...data?.getAllPeople?.data], type: post?.type, count: data?.getAllPeople?.count }
})

const fetchAllPeopleSlice = createSlice({
  name: 'fetchPeople',
  initialState,
  reducers: {
    setJoinedStoreState: (state: any, action) => {
      state.getAllStore = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPeople.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(fetchAllPeople.fulfilled, (state, action) => {
      state.loading = false
      state.people_total_count = action?.payload?.count
      switch (action?.payload?.type) {
        case 'FOLLOWERS':
          state.followers = action?.payload?.data
          break
        case 'FOLLOWING':
          state.following = action?.payload?.data
          break
        case 'SUGGESTED_PEOPLE':
          state.suggestedPeople = action?.payload?.data
          break
        case 'ALL_PEOPLE':
          state.allPeople = action?.payload?.data
          break
        default:
          break
      }
    })
    builder.addCase(fetchAllPeople.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export default fetchAllPeopleSlice.reducer
